<template>
  <transition name="background-popup">
    <div v-if="$store.getters.loading" class="load relative light">
      <div class="icon center-flex">
        <img class="avatar-normal" src="../../assets/img/Rolling90px.gif" />
      </div>
      <img class="box" src="../../assets/img/load4.gif" />
    </div>
  </transition>
</template>
<script>
import ButtonIcon from "../Buttons/ButtonIcon";
export default {
  components: {
    ButtonIcon
  }
};
</script>
<style scoped>
.icon {
  height: 50px;
  position: absolute;
  right: 5vw;
  z-index: 700;
  top: 0;
}
.box {
  width: 300px;
}
.load {
  opacity: 0.85;
  height: 100vh;
  width: 100%;
  z-index: 500;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7fbff;
}
</style>