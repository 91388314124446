<template>
  <div>
    <div class="circle light -one"></div>
    <div class="circle light -two"></div>
    <div class="toolbar bar center-flex white anchor primary">
      <button-icon
        v-if="$route.name == 'Dashboard' || $route.name == 'Welcome'"
        @click.native="$store.commit('lateralMenu')"
        class="ml-3"
        icon="mdi-buffer"
      ></button-icon>
      <button-icon
        v-else
        @click.native="$router.push('/Home/Dashboard').catch (err => {})"
        class="ml-3"
        icon="mdi-arrow-left"
      ></button-icon>
      <div class="spacer"></div>

      <button-icon
        class="mr-3"
        v-if="$store.getters.isAdmin"
        @click.native="$store.commit('search')"
        icon="mdi-magnify"
      ></button-icon>

      <!-- USERS  -->
      <section class="toolbar" v-if="!$store.getters.isAnonymous">
        <div
          v-if="$store.getters.news !== null"
          class="badge red caption center center-flex"
        >{{Object.values($store.getters.news).length}}</div>
        <button-icon
          class="mr-3"
          @click.native="$router.push('/Home/Messages').catch (err => {})"
          icon="mdi-email-outline"
        ></button-icon>

        <avatar
          @click.native="$router
        .push({ name: 'Profile', params: { uid: $store.getters.myProfile.uid } })
        .catch(err => {})"
          class="mr-5"
          size="normal"
          :photo="$store.getters.myProfile.photo"
        ></avatar>
      </section>
    </div>
    <div class="box">
      <div class="box primary"></div>
      <div class="gradient-secondary box-2"></div>
    </div>
    <div class="overflow-loading">
      <transition name="fade-home" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import Avatar from "../../components/Buttons/Avatar.vue";
import ButtonIcon from "../../components/Buttons/ButtonIcon.vue";
export default {
  created() {
    this.$store.dispatch("myProfile");
    this.$store.dispatch("dashboard");
  },
  components: {
    ButtonIcon,
    Avatar
  }
};
</script>
<style scoped>
.badge {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  font-size: 10px;
  position: absolute;
  top: 15px;
  right: calc(8.5vw + 30px);
}
.box-2 {
  height: 65vh;
}
.box {
  height: 50px;
}
.bar {
  height: 50px;
  position: fixed;
  z-index: 100;
}
.circle {
  user-select: none;
  position: fixed;
  border-radius: 50%;
  opacity: 0.02;
  z-index: 200;
}
.circle.-one {
  right: 23vw;
  top: 15vw;
  width: 30px;
  height: 30px;
}
.circle.-two {
  width: 70px;
  height: 70px;
  right: 10vw;
  top: 15vw;
}
</style>