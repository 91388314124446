<template>
  <div class="toolbar">
    <div class="spacer">
      <div class="center body-2 medium white">{{$store.getters.servicesText}}</div>
      <div class="center bold title-2 white">{{data.Services}}</div>
    </div>
    <hr class="vert mr-3" />
    <div class="spacer">
      <div class="center body-2 medium white">{{$store.getters.confirmedText}}</div>
      <div class="center bold title-2 white">{{data.Confirmed}}</div>
    </div>
    <hr class="vert ml-3" />
    <div class="spacer">
      <div class="center body-2 medium white">{{$store.getters.canceledText}}</div>
      <div class="center bold title-2 white">{{data.Cancel}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  }
};
</script>
<style scoped>
hr.vert {
  border: none;
  height: 22px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.712);
}
</style>