<template>
  <div :class="`gradient-shifts-${index}`" class="toolbar bizel pl-3 pr-5 height center-flex">
    <avatar size="normal mr-3" :photo="data[1].photo"></avatar>
    <div class="spacer mr-3">
      <div class="normal">{{data[0]}}</div>
      <div class="caption capital">{{data[1].name}}</div>
    </div>
    <span :class="icon" class="mdi"></span>
  </div>
</template>
<script>
import Avatar from "../../components/Buttons/Avatar.vue";
export default {
  components: {
    Avatar
  },
  props: {
    data: Array,
    index: Number
  },
  computed: {
    icon() {
      switch (this.index) {
        case 0:
          return "mdi-weather-partly-cloudy";
        case 1:
          return "mdi-white-balance-sunny";
        case 2:
          return "mdi-weather-night";
      }
    }
  }
};
</script>
<style scoped>
.height {
  height: 45px;
}
.toolbar:active {
  opacity: 0.8;
  transform: scale(0.98);
}
</style>