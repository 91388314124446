import store from "../store";

const actions = {
  programing({}, payload) {
    // for (let index = 0; index < payload.length; index++) {
    //   cordova.plugins.notification.local.schedule({
    //     id: index,
    //     smallIcon: "res://mipmap-xxxhdpi/ic_launcher.png",
    //     title: `${store.getters.appName}`,
    //     text: `${store.getters.alertText} ${payload[index].text}`,
    //     trigger: { at: payload[index].date },
    //     wakeup: true
    //   });
    // }
  },
  goLink({}, url) {
    // cordova.InAppBrowser.open(url, "_system");
    window.open(
      url,
      "_blank",
      "toolbar=no,top=50,left=10,width=500,height=600"
    );
  }
};

export default {
  actions
};
