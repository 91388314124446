  
<template>
  <transition name="upTicket">
    <div v-if="$store.getters.register" class="red load">
      <div class="card bizel light">
        <!-- HEADER -->
        <section class="mb-1">
          <div class="toolbar mt-5 mb-2 center-flex">
            <img class="box" src="../../assets/img/think.svg" />
          </div>
          <div class="medium subtitle center capital">{{$store.getters.registerText}}</div>
        </section>
        <!-- Message -->
        <section class="mb-4 grey-text text center pr-5 pl-5">{{$store.getters.registerBody}}</section>
        <!-- OPTIONS -->
        <section class="mb-4">
          <div class="center-flex item mr-5 ml-5">
            <button-icon
              @click.native="$store.commit('closed')"
              class="red mr-2 bizel bizel avatar-big white subtitle uppercased medium"
              icon="mdi-close"
            ></button-icon>
            <button-text
              @click.native="$store.dispatch('logout')"
              class="accent bizel white spacer text uppercased medium"
              :text="$store.getters.registerOption1"
            ></button-text>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import ButtonText from "../Buttons/ButtonText";
import ButtonIcon from "../Buttons/ButtonIcon";
export default {
  components: {
    ButtonText,
    ButtonIcon
  },
  methods: {}
};
</script>
<style scoped>
.load {
  position: fixed;
  z-index: 500;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 150px;
  height: 120px;
}
.card {
  border-radius: 20px;
  position: absolute;
  top: 25vh;
  width: 250px;
}
</style>