<template>
  <transition name="turn" mode="out-in">
    <div
      @click.stop="toBack"
      key="1"
      v-if="front"
      :class="`gifts-${data.id}`"
      class="bizel height pa-5"
    >
      <div class="toolbar relative">
        <div class="avatar-small zindex-100 light"></div>
        <div class="avatar-small circle light"></div>
        <div class="one light ml-5"></div>
        <div class="two light"></div>

        <div class="spacer"></div>
        <div
          class="normal white"
        >{{('0' + data.tickets).slice(-2)}} {{$store.getters.ticketsText}}</div>
      </div>
      <div class="spacer"></div>
      <div class="normal subtitle medium white">{{data.name}}</div>
      <div class="text white">{{data.price}}</div>
    </div>

    <!-- back front -->
    <div
      @click.stop="toFront"
      v-else
      key="2"
      :class="`gifts-${data.id}`"
      class="bizel relative height pa-5"
    >
      <div class="bar anchor zindex-200 grey-text"></div>
      <div class="one light ml-5"></div>
      <div class="two light ml-5"></div>
      <div class="body-2 medium white">{{$store.getters.termsMenu}}</div>
      <div class="caption white">{{data.information}}</div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data: () => ({
    front: true
  }),
  methods: {
    toBack() {
      this.front = false;
    },
    toFront() {
      this.front = true;
    }
  }
};
</script>
<style scoped>
.bar {
  background: #303030;
  height: 5vw;
  left: 0;
  bottom: 10px;
  position: absolute;
}
.circle {
  position: absolute;
  opacity: 0.7;
  margin-left: 3vw;
}
.one {
  top: -13vw;
  right: -10vw;
  height: 48vw;
  width: 48vw;
  border-radius: 50%;
  position: absolute;
  opacity: 0.1;
}
.two {
  top: 10vw;
  right: -10vw;
  height: 65vw;
  opacity: 0.1;
  width: 65vw;
  border-radius: 50%;
  position: absolute;
}

.height {
  height: 30vw;
  width: 50vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/* .height:active {
  opacity: 0.8;
  transform: scale(0.98);
} */
</style>