import axios from "axios";

import router from "../router";
import store from "../store";
import firebase from "../plugins/firebase";
const state = {
  dashboard: null,
  profile: null,
  campus: null,
  myProfile: {
    admin: false,
    email: "",
    name: "",
    phone: "",
    photo: "A",
    uid: "@"
  },
  admin: null,
  messages: null,
  news: null
};
const mutations = {
  news(state, payload) {
    state.news = payload;
    if (
      payload &&
      state.myProfile.uid != "@" &&
      router.history.current.name !== "Welcome" &&
      !state.myProfile.admin
    ) {
      setTimeout(() => {
        store.commit("closed");
        store.commit("newMessage");
      }, 2000);
    }
  },
  admin(state, payload) {
    state.admin = payload;
  },
  messages(state, payload) {
    if (payload) {
      state.messages = payload.reverse();
    } else {
      state.messages = payload;
    }
  },
  myProfile(state, payload) {
    state.myProfile = payload;
  },
  profile(state, payload) {
    state.profile = payload;
  },
  dashboard(state, payload) {
    state.dashboard = payload;
  },
  campus(state, payload) {
    state.campus = payload;
  }
};
const perf = firebase.performance();
const actions = {
  searchUser({}, email) {
    const trace = perf.trace("searchUser");
    trace.start();
    store.commit("closed");
    store.commit("loading", true);
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/searchUser",
        {
          params: {
            email: email
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          router
            .push({
              name: "Profile",
              params: { uid: response.data }
            })
            .catch(err => {});
          store.commit("loading", false);
        } else {
          store.commit("loading", false);
          store.commit("message", {
            text: store.getters.offUserError,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  chargeTicket({}, uid) {
    const trace = perf.trace("chargeTicket");
    trace.start();
    store.commit("closed");
    store.commit("loading", true);
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/chargeTicket",
        {
          params: {
            uid: uid
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          router.push("/Home/Dashboard");
          store.commit("loading", false);
          store.commit("message", {
            text: store.getters.chargeDone,
            color: "green"
          });
        } else {
          store.commit("loading", false);
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  sellGift({}, payload) {
    const trace = perf.trace("sellGift");
    trace.start();
    store.commit("closed");
    store.commit("loading", true);
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/sellGift",
        {
          params: payload
        }
      )
      .then(response => {
        if (response.status == 200) {
          router.push("/Home/Dashboard");
          store.commit("loading", false);
          store.commit("message", {
            text: store.getters.sellDone,
            color: "green"
          });
        } else {
          store.commit("loading", false);
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  cancelShift({}, payload) {
    const trace = perf.trace("cancelShift");
    trace.start();
    store.commit("loading", true);
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/cancelShift",
        {
          params: {
            app: store.getters.database, //id base de datos
            uid: payload.uid, // id del usuario
            node: payload.node,
            serverId: payload.serverId,
            hour: payload.hour,
            hash: payload.hash //hash del servicio de usuario
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          store.commit("loading", false);
          store.commit("closed");
          router.replace("/Home/Dashboard");
          store.commit("message", {
            text: store.getters.cancelDone,
            color: "green"
          });
        } else {
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  news({ commit }) {
    const trace = perf.trace("news");
    trace.start();
    axios
      .get(
        `https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/newsMessages`,
        {
          params: {
            app: store.getters.database
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          commit("news", response.data);
        }
        trace.stop();
      });
  },
  sendMessage({}, text) {
    const trace = perf.trace("sendMessage");
    trace.start();
    store.commit("loading", true);
    axios
      .get(
        `https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/sendMessage`,
        {
          params: {
            text: text,
            app: store.getters.database,
            name: store.getters.myProfile.name,
            photo: store.getters.myProfile.photo
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          store.commit("loading", false);
          store.commit("closed");
          router.replace("/Home/Messages");
          store.commit("message", {
            text: store.getters.sendDone,
            color: "green"
          });
        } else {
          store.commit("closed");
          store.commit("loading", false);
          store.commit("message", {
            text: store.getters.SendError,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  messages({ commit }) {
    const trace = perf.trace("messages");
    trace.start();
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/getMessages",
        {
          params: {
            app: store.getters.database
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          commit("messages", response.data);
        } else {
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  admin({ commit }) {
    const trace = perf.trace("admin");
    trace.start();
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/getAdmin",
        {
          params: {
            id: store.getters.database,
            max: store.getters.maxDays
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          commit("admin", response.data);
        } else {
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  addShift({}, payload) {
    const trace = perf.trace("addShift");
    trace.start();
    store.commit("closed");
    store.commit("loading", true);
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/addShift",
        {
          params: payload
        }
      )
      .then(response => {
        if (response.status == 200) {
          store.dispatch("programing", response.data);
          router.push({
            name: "Profile",
            params: { uid: store.getters.myProfile.uid }
          });
          store.commit("loading", false);
        } else {
          store.commit("loading", false);
          store.commit("message", {
            text: store.getters.SyncError,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  campus({ commit }, campus) {
    const trace = perf.trace("campus");
    trace.start();
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/getCampus",
        {
          params: {
            id: store.getters.database,
            campus: campus,
            max: store.getters.maxDays
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          commit("campus", response.data);
        } else {
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  dashboard({ commit, dispatch }) {
    const trace = perf.trace("dashboard");
    trace.start();
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/getDahboard",
        {
          params: {
            app: store.getters.database
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          commit("dashboard", response.data);
          dispatch("news");
        } else {
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  profile({ commit }, uid) {
    const trace = perf.trace("profile");
    trace.start();
    axios
      .get(
        "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/getProfile",
        {
          params: {
            uid: uid
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          commit("profile", response.data);
        } else {
          store.commit("message", {
            text: "[ FATAL ] " + response.data,
            color: "red"
          });
        }
        trace.stop();
      });
  },
  myProfile({ commit }) {
    const trace = perf.trace("myProfile");
    trace.start();
    if (
      firebase.auth().currentUser != null &&
      !firebase.auth().currentUser.isAnonymous
    ) {
      store.commit("loading", true);
      axios
        .get(
          "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/getMyProfile",
          {
            params: {
              uid: firebase.auth().currentUser.uid
            }
          }
        )
        .then(response => {
          if (response.status == 200) {
            commit("myProfile", response.data);
            store.commit("loading", false);
          } else {
            store.commit("loading", false);
            store.commit("message", {
              text: "[ FATAL ] " + response.data,
              color: "red"
            });
          }
          trace.stop();
        });
    } else {
      store.commit("loading", false);
      // NALIAXZR
    }
  },
  anonymous() {
    const trace = perf.trace("anonymous");
    trace.start();
    store.commit("loading", true);
    try {
      firebase
        .auth()
        .signInAnonymously()
        .then(
          () => {
            trace.stop();
            router.push("/Home/Dashboard");
          },
          error => {
            store.commit("loading", false);
            store.commit("message", {
              text: error.message,
              color: "red"
            });
          }
        );
    } catch (error) {
      trace.stop();
      store.commit("loading", false);
      store.commit("message", {
        text: error.message,
        color: "red"
      });
    }
  },
  login({}, payload) {
    const trace = perf.trace('login');
    trace.start();
    store.commit("loading", true);
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(
          () => {
            trace.stop();
            if (firebase.auth().currentUser) {
              router.push("/Home/Dashboard");
            }
          },
          error => {
            store.commit("loading", false);
            store.commit("message", {
              text: error.message,
              color: "red"
            });
          }
        );
    } catch (error) {
      trace.stop();
      store.commit("loading", false);
      store.commit("message", {
        text: error.message,
        color: "red"
      });
    }
  },
  signup({}, payload) {
    const trace = perf.trace('signup');
    trace.start();
    store.commit("loading", true);
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(
          response => {
            axios
              .get(
                "https://us-central1-api-7211816181825408403-893427.cloudfunctions.net/createUser",
                {
                  params: {
                    uid: response.user.uid,
                    email: payload.email,
                    name: payload.name,
                    phone: payload.phone
                  }
                }
              )
              .then(response => {
                if (response.status == 200) {
                  router.replace("/Home/Dashboard");
                } else {
                  store.commit("loading", false);
                  store.commit("message", {
                    text: "[ FATAL ] " + response.data,
                    color: "red"
                  });
                }
                trace.stop();
              });
          },
          error => {            
            store.commit("loading", false);
            store.commit("message", {
              text: error.message,
              color: "red"
            });
          }
        );
    } catch (error) {
      trace.stop();
      store.commit("loading", false);
      store.commit("message", {
        text: error.message,
        color: "red"
      });
    }
  },
  logout() {
    const trace = perf.trace("addShift");
    trace.start();
    firebase
      .auth()
      .signOut()
      .then(() => {
        trace.stop();
        location.reload();
      });
  }
};
const getters = {
  news: state => (state.news == "" ? null : state.news),
  campus: state => state.campus,
  messages: state => state.messages,
  admin: state => state.admin,
  dashboard: state => state.dashboard,
  myProfile: state => state.myProfile,
  profile: state => state.profile,
  isAnonymous: state => (state.myProfile.uid == "@" ? true : false),
  isAdmin: state => state.myProfile.admin
};
export default {
  getters,
  mutations,
  actions,
  state
};
