<template>
  <div class="relative">
    <span class="mdi white mdi-information-variant info"></span>
    <img class="card mb-1 bizel gradient-primary bizel anchor" :src="data.photo" />
    <div class="medium overflow pb-1 text">{{data.name}}</div>
    <div class="body medium">$ {{data.price}}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  }
};
</script>
<style scoped>
.info {
  position: absolute;
  right: 3vw;
  top: 2vw;
}
.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 38vw;
  white-space: nowrap;
}
.card {
  height: 60vw;
  width: 40vw;
}
.card:active {
  opacity: 0.8;
  transform: scale(0.98);
}
</style>