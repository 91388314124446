<template>
  <div>
    <div class="big-title pl-5 white medium mb-5">{{$route.params.campus}}</div>
    <!-- Servers -->
    <section class="mb-5">
      <div class="toolbar">
        <div class="subtitle pl-5 white spacer medium mb-3">{{$store.getters.serversText}}</div>
        <div class="capital medium normal white mr-5">{{data.servidors[selected.server].name}}</div>
      </div>
      <div class="horizon">
        <avatar
          @click.native="select(index,'server')"
          v-for="(item, index) in data.servidors"
          :key="index"
          :class="index !== selected.server ? 'no-selected' : 'selected'"
          class="ml-5 item"
          size="max"
          :photo="item.photo"
        ></avatar>
        <div class="item pr-5"></div>
      </div>
    </section>
    <!-- Days -->
    <section class="mb-5">
      <div class="toolbar">
        <div class="subtitle pl-5 spacer medium mb-3 capital">{{data.week[selected.day].month}}</div>
        <div class="capital normal medium mr-5">{{data.week[selected.day].dayWeek}}</div>
      </div>
      <div class="horizon">
        <days
          @click.native="select(index,'day')"
          v-for="(item, index) in data.week"
          :key="index"
          :selected="index !== selected.day ? '' : 'gradient-primary white'"
          class="ml-5 item"
          size="big"
          :data="item"
        ></days>
        <div class="item pr-5"></div>
      </div>
    </section>
    <!-- Service -->
    <section class="mb-5">
      <div class="subtitle pl-5 medium mb-3">{{$store.getters.servicesText}}</div>
      <div class="horizon">
        <div class="item pl-5"></div>
        <div
          @click.stop="select(index,'service')"
          v-for="(item, index) in Object.values(data.servidors[selected.server].services)"
          :key="index"
          :class="index !== selected.service ? '' : 'gradient-primary white'"
          class="item mr-2 capital text bizel pr-3 pl-3 pt-2 pb-2 center"
        >{{item.name}}</div>
        <div class="item pr-3"></div>
      </div>
    </section>
    <!-- Schedule -->
    <section class="mb-5">
      <div v-if="empty" class="pa-12 mt-8 grey-text flex-colum center-flex">
        <div class="mdi subtitle mdi-alert-circle-outline"></div>
        <div class="body-2 center pr-8 pl-8 mt-2">{{$store.getters.offServiceText}}</div>
      </div>

      <div
        v-else
        v-for="(item, index) in data.servidors[selected.server].schedule[selected.day]"
        :key="index"
        class="pr-5 mb-5 pl-5"
      >
        <div
          v-if="item.free.length !== 0"
          class="mr-3 normal medium mb-1"
        >{{$store.getters[item.name]}}</div>
        <shifts
          @click.native="select(hour,'shift')"
          :index="index"
          v-for="(hour, index2) in item.free"
          :data="hour"
          :interval="data.servidors[selected.server].interval"
          :key="index2"
          class="spacer namespace mb-2"
        ></shifts>
      </div>
    </section>
  </div>
</template>
<script>
import Avatar from "../../../components/Buttons/Avatar";
import Days from "../../../components/Schedule/Days";
import Shifts from "../../../components/Schedule/Shifts";
export default {
  created() {
    // ASIGNAR VALORES INCIALES
    this.payload.campus = this.$route.params.campus;
    this.payload.node = this.data.week[0].node;
    this.payload.date =
      this.data.week[0].month + " " + this.data.week[0].dayMonth;
    this.payload.serverId = this.data.servidors[0].id;
    this.payload.serverName = this.data.servidors[0].name;
    this.payload.serverPhoto = this.data.servidors[0].photo;
    this.payload.price = Object.values(
      this.data.servidors[0].services
    )[0].price;
    this.payload.service = Object.values(
      this.data.servidors[0].services
    )[0].name;
  },
  components: {
    Avatar,
    Days,
    Shifts
  },
  props: {
    data: Object
  },
  data: () => ({
    selected: {
      server: 0,
      day: 0,
      service: 0
    },
    payload: {
      node: "",
      date: "",

      serverId: "",
      serverPhoto: "",
      serverName: "",

      price: "",
      service: "",

      campus: "",
      hour: ""
    }
  }),
  methods: {
    select(index, param) {
      switch (param) {
        case "service":
          this.selected.service = index;

          break;
        case "day":
          this.selected.day = index;

          break;
        case "server":
          this.selected.server = index;

          break;
        case "shift":
          this.payload.hour = index;

          this.payload.price = Object.values(
            this.data.servidors[this.selected.server].services
          )[this.selected.service].price;

          this.payload.service = Object.values(
            this.data.servidors[this.selected.server].services
          )[this.selected.service].name;

          this.payload.node = this.data.week[this.selected.day].node;

          this.payload.date =
            this.data.week[this.selected.day].month +
            " " +
            this.data.week[this.selected.day].dayMonth;

          this.payload.serverPhoto = this.data.servidors[
            this.selected.server
          ].photo;

          this.payload.serverName = this.data.servidors[
            this.selected.server
          ].name;

          this.payload.serverId = this.data.servidors[this.selected.server].id;
          this.$store.commit("ticket", this.payload);
          break;
      }
    }
  },
  computed: {
    empty() {
      const block = this.data.servidors[this.selected.server].schedule[
        this.selected.day
      ];
      return (
        block[0].free.length == 0 &&
        block[1].free.length == 0 &&
        block[2].free.length == 0
      );
    }
  }
};
</script>
<style scoped>
.namespace {
  margin-left: 90px;
}
.no-selected {
  opacity: 0.3;
  border: 2px solid white;
  box-sizing: border-box;
}
.selected {
  border: 2px solid var(--primary);
  box-sizing: border-box;
}
</style>