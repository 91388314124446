<template>
  <div>
    <div class="big-title pl-5 white capital medium mb-5">{{$store.getters.shopMenu}}</div>
    <!-- Servers -->
    <section class="mb-3">
      <div class="subtitle pl-5 white medium">{{$store.getters.shopText}}</div>
    </section>
    <!-- ITEMS SHOP -->
    <section v-if="$store.getters.dashboard != null" class="wrap pr-2 pl-2 pb-5">
      <item-shop
        @click.native="$store.commit('shopDetail', item)"
        v-for="(item, index) in $store.getters.dashboard.Shop"
        :key="index"
        class="mr-1 ml-1 mb-3 item"
        :data="item"
      ></item-shop>
    </section>
  </div>
</template>
<script>
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import ItemShop from "../../components/Shop/ItemShop";
export default {
  components: {
    ButtonIcon,
    ItemShop
  }
};
</script>
<style scoped>
.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>