<template>
   <div id="app">
    <popup></popup>
    <router-view />
  </div>
</template>

<script>
import Popup from "./components/Popup/Popup";
export default {

  components: {
    Popup
  }
};
</script>