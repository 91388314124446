import Vue from "vue";

import Vuex from "vuex";

import app from "./app";
import cordova from "./cordova";
import backend from "./backend";
import popups from "./popups";
import strings from "./strings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cordova,
    app,
    popups,
    strings,
    backend
  }
});
