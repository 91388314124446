const getters = {
  database: () => "1002",
  version: () => "v2.0.0 Beta",
  appName: () => "James Barbería",
  maxDays: () => "20",
  prefix: () => "+57",
  package: () => "co.com.complex.barber.james",
  systems: () => ["android", "ios", "web"]
};

export default {
  getters
};
