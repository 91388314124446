<template>
  <div>
    <div class="big-title pl-5 white capital medium mb-5">{{$store.getters.appName}}</div>
    <!-- Servers -->
    <section class="mb-5">
      <div class="subtitle pl-5 white medium mb-3">{{$store.getters.serversText}}</div>
      <div class="horizon">
        <avatar
          @click.native="$router.push({name: 'Schedule', params: { campus: item.campus }})"
          v-for="(item, index) in randomElements(data.Servers, data.Servers.length)"
          :key="index"
          class="ml-4 item border-white"
          size="big"
          :photo="item.photo"
        ></avatar>
        <div class="item pr-5"></div>
      </div>
    </section>
    <!-- Shop -->
    <section class="mb-5">
      <div class="subtitle pl-5 white medium mb-3">{{$store.getters.shopText}}</div>
      <div class="horizon">
        <item-shop
          @click.native="$store.commit('shopDetail', item)"
          v-for="(item, index) in randomElements(data.Shop,3)"
          :key="index"
          class="ml-5 item"
          :data="item"
        ></item-shop>
        <more-shop @click.native="$router.push('Shop')" class="ml-5"></more-shop>
        <div class="item pr-5"></div>
      </div>
    </section>
    <!-- Campus -->
    <section class="mb-5">
      <div class="subtitle pl-5 medium mb-3">{{$store.getters.campusText}}</div>
      <div class="pr-3 pl-3">
        <post v-for="(item, index) in data.Campus" :key="index" class="item mb-2" :data="item"></post>
      </div>
    </section>
    <!-- Gifts -->
    <section class="mb-5">
      <div class="subtitle pl-5 medium mb-3">{{$store.getters.giftsText}}</div>
      <div class="horizon">
        <gift v-for="(item, index) in data.Gifts" :key="index" class="ml-5 item" :data="item"></gift>
        <div class="item pr-5"></div>
      </div>
    </section>
    <!-- Galery -->
    <section class="mb-5">
      <div class="subtitle pl-5 medium mb-3">{{$store.getters.galeryText}}</div>
      <galery
        @click.native="$store.dispatch('goLink',`https://www.instagram.com/${$store.getters.dashboard.App.instagram}`)"
        :data="data.Galery"
      ></galery>
    </section>
    <!-- COPYRIGHT -->
    <section>
      <div
        @click.stop="$store.dispatch('goLink',`${$store.getters.developerURL}`)"
        class="center caption grey-text ma-5"
      >
        {{$store.getters.textTerms}}
        <strong>{{$store.getters.developer}}</strong>
        {{$store.getters.version}}
      </div>
    </section>
  </div>
</template>
<script>
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import Avatar from "../../../components/Buttons/Avatar";
import ItemShop from "../../../components/Dashboard/ItemShop";
import MoreShop from "../../../components/Dashboard/MoreShop";
import Post from "../../../components/Dashboard/Post";
import Gift from "../../../components/Dashboard/Gift";
import Galery from "../../../components/Dashboard/Galery";
export default {
  components: {
    ItemShop,
    ButtonIcon,
    Avatar,
    Gift,
    Post,
    Galery,
    MoreShop
  },
  props: {
    data: Object
  },
  methods: {
    randomElements(array, max) {
      if (array) {
        var a = Object.values(array);
        for (let i = a.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [a[i], a[j]] = [a[j], a[i]];
        }
        return a.slice(0, max);
      } else {
        return [];
      }
    }
  }
};
</script>
