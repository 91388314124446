<template>
  <div class="relative bizel">
    <span class="mdi white mdi-information-variant info"></span>
    <img class="card anchor gradient-primary bizel" :src="data.photo" />
    <div class="medium overflow pt-1 pb-1 pl-1 text">{{data.name}}</div>
    <div class="body medium pl-1 overflow pb-1">$ {{data.price}}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  }
};
</script>
<style scoped>
.info {
  position: absolute;
  right: 1vw;
  top: 2vw;
}
.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 26vw;
}
.card {
  height: 45vw;
  width: 30vw;
}
.card:active {
  opacity: 0.8;
  transform: scale(0.98);
}
</style>