<template>
  <div>
    <!-- PUNTOS Y PERFIL -->
    <section class="mb-5 pr-5 pl-5">
      <div class="toolbar">
        <avatar size="big" class="white subtitle" :photo="data.Profile.photo"></avatar>
        <div class="spacer pl-3 white">
          <div class="capital pt-1 medium">{{data.Profile.name}}</div>
          <div class="body mt-1 grey-text">{{$store.getters.prefix}} {{data.Profile.phone}}</div>
        </div>
      </div>
      <points class="mt-5" :data="data.Information"></points>
    </section>

    <!-- GIFT CARD -->
    <section class="mb-5 pr-5 pl-5">
      <div class="subtitle white medium mb-3">{{$store.getters.giftsText}}</div>
      <gift class="ma-1 pr-4 pl-4" :data="data.Gifts"></gift>
    </section>

    <!-- PROXIMOS SERVICIOS -->
    <section class="mb-5">
      <div class="subtitle medium mb-3 pl-5">{{$store.getters.schedulesText}}</div>
      <div v-if="data.NearServices.length > 0" class="horizon">
        <card-service
          @click.native="goTo(item)"
          class="ml-5 item"
          v-for="(item, index) in data.NearServices"
          :key="index"
          :data="item[1]"
        ></card-service>
        <div class="item pr-5"></div>
      </div>
      <div v-else class="pa-8 grey-text flex-colum center-flex">
        <div class="mdi subtitle mdi-alert-circle-outline"></div>
        <div class="body-2 center pr-8 pl-8 mt-2">{{$store.getters.offMyServiceText}}</div>
      </div>
    </section>

    <!-- ADMINISTRADOR -->
    <section v-if="$store.getters.isAdmin" class="mb-5">
      <div class="subtitle medium mb-3 pl-5">{{$store.getters.adminMenu}}</div>
      <div class="center-flex item mr-5 ml-5">
        <button-icon
          @click.native="$store.dispatch('goLink',`tel:${$store.getters.prefix}${data.Profile.phone}`)"
          class="green avatar-big mr-2 white bizel"
          icon="mdi-phone"
        ></button-icon>
        <button-icon
          @click.native="$store.dispatch('goLink',`https://wa.me/${$store.getters.prefix}${data.Profile.phone}`)"
          class="green avatar-big white mr-2 bizel"
          icon="mdi-whatsapp"
        ></button-icon>
        <button-text
          @click.native="$store.commit('sellGift')"
          class="bizel primary white accent mr-2 spacer text uppercased medium"
          :text="$store.getters.addGift"
        ></button-text>
        <button-text
          @click.native="$store.commit('confirm')"
          class="bizel red white accent spacer text uppercased medium"
          :text="$store.getters.chargeTicket"
        ></button-text>
      </div>
    </section>
  </div>
</template>
<script>
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import Avatar from "../../../components/Buttons/Avatar";
import ButtonText from "../../../components/Buttons/ButtonText";
import CardService from "../../../components/Profile/CardService";
import Points from "../../../components/Profile/Points";
import Gift from "../../../components/Profile/Gift";

export default {
  props: {
    data: Object
  },
  components: {
    Avatar,
    CardService,
    Points,
    Gift,
    ButtonIcon,
    ButtonText
  },
  methods: {
    goTo(item) {
      var payload = item[1];
      payload.hash = item[0];
      this.$store.commit("ticket", payload);
    }
  }
};
</script>
<style scoped>
hr.vert {
  border: none;
  height: 22px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.712);
}
</style>