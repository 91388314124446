<template>
  <transition name="zoom">
    <div v-if="$store.getters.shopDetail" class="load">
      <div class="icon center-flex">
        <button-icon @click.native="$store.commit('closed')" class="medium primary white" icon="mdi-close" />
      </div>
      <img class="load2" :src="$store.getters.dataPop.photo" />
      <div class="load3" />
      <div v-if="$store.getters.dataPop !=  null" class="card-up flex-colum black white">
        <div class="spacer"></div>
        <div class="pr-5 pl-5 mt-3 mb-3">
          <div class="subtitle medium capital">{{$store.getters.dataPop.name}}</div>

          <div class="text justify mt-1 mb-1">{{$store.getters.dataPop.information}}</div>
        </div>
        <div class="toolbar pr-5 pl-5 mb-5">
          <button-icon
            @click.native="$store.dispatch('goLink',`tel:${$store.getters.prefix}${$store.getters.dashboard.App.contact}`)"
            class="green bizel avatar-big mr-3"
            icon="mdi-phone"
          ></button-icon>
          <button-text
            @click.native="$store.dispatch('goLink',`https://wa.me/${$store.getters.prefix}${$store.getters.dashboard.App.contact}?text=${$store.getters.itemInformationWP} ${$store.getters.dataPop.name} [ $${$store.getters.dataPop.price} ]`)"
            class="accent spacer medium bizel"
            :text="'$'+$store.getters.dataPop.price"
          ></button-text>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import ButtonIcon from "../Buttons/ButtonIcon";
import ButtonText from "../Buttons/ButtonText";
export default {
  components: {
    ButtonText,
    ButtonIcon
  }
};
</script>
<style scoped>
.icon {
  height: 50px;
  position: absolute;
  right: 5vw;
  z-index: 700;
}
.load2 {
  position: absolute;
  z-index: 200;
  width: 100vw;
  height: 100vh;
}
.load3 {
  opacity: .8;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--primary) 90%);
  position: absolute;
  z-index: 300;
  width: 100vw;
  height: 100vh;
}
.load {
  position: fixed;
  z-index: 700;
  width: 100vw;
  height: 100vh;
}
.card-up {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  background: transparent;
}
</style>