const state = {
  popup: false,
  register: false,

  newMessage: false,
  confirm: false,
  shopSearch: false,

  sellGift: false,
  loading: false,
  shopDetail: false,
  ticket: false,
  lateralMenu: false,
  message: false,
  search: false,
  dataPop: null
};
const mutations = {
  closed(state) {
    state.popup = false;
    state.lateralMenu = false;
    state.search = false;
    state.message = false;
    state.ticket = false;
    state.shopDetail = false;
    state.sellGift = false;
    state.newMessage = false;
    state.shopSearch = false;
    state.register = false;
    state.confirm = false;
  },
  message(state, payload) {
    state.dataPop = null;
    state.dataPop = payload;
    state.popup = true;
    state.message = true;
  },
  lateralMenu(state) {
    state.lateralMenu = true;
    state.popup = true;
  },
  search(state) {
    state.popup = true;
    state.search = true;
  },
  ticket(state, payload) {
    state.popup = true;
    state.ticket = true;
    state.dataPop = null;
    state.dataPop = payload;
  },
  shopDetail(state, payload) {
    state.dataPop = null;
    state.dataPop = payload;
    state.shopDetail = true;
  },
  loading(state, flag) {
    state.loading = flag;
  },
  sellGift(state) {
    state.popup = true;
    state.sellGift = true;
  },
  newMessage(state) {
    state.popup = true;
    state.newMessage = true;
  },
  confirm(state) {
    state.popup = true;
    state.confirm = true;
  },
  shopSearch(state) {
    state.popup = true;
    state.shopSearch = true;
  },
  register(state) {
    state.popup = true;
    state.lateralMenu = false;
    state.ticket = false;
    setTimeout(() => {
      state.register = true;
    }, 400);
  }
};
const getters = {
  shopSearch: state => state.shopSearch,
  register: state => state.register,
  confirm: state => state.confirm,
  sellGift: state => state.sellGift,
  newMessage: state => state.newMessage,
  loading: state => state.loading,
  shopDetail: state => state.shopDetail,
  ticket: state => state.ticket,
  popup: state => state.popup,
  message: state => state.message,
  search: state => state.search,
  lateralMenu: state => state.lateralMenu,
  dataPop: state => state.dataPop
};

export default {
  state,
  getters,
  mutations
};
