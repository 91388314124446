import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import firebase from "./plugins/firebase";
import "./assets/css/colors.css";
import "./assets/css/loading.css";
import "./assets/css/theme.css";
import "./assets/css/transitons.css";
Vue.config.productionTip = false;
firebase.auth().onAuthStateChanged(function() {
  firebase.performance();
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
