<template>
  <div>
    <div v-if="data.dayWeek =='miércoles'" class="avatar-normal normal pa-1 bold center-flex uppercased">X</div>
    <div v-else class="avatar-normal bold center-flex normal pa-1 uppercased">{{data.dayWeek.charAt(0)}}</div>
    <div :class="selected" class="avatar-normal normal pa-1 center-flex">{{data.dayMonth}}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
    selected: String
  }
};
</script>
