<template>
  <div class="overflow-loading">
    <transition name="fade-loading" mode="out-in">
      <loading v-if="$store.getters.campus == null"></loading>
      <master :data="$store.getters.campus" v-else></master>
    </transition>
  </div>
</template>
<script>
import master from "./Master";
import loading from "./Loading";
export default {
  components: {
    master,
    loading
  },
  created() {
     this.$store.commit("campus",null);
    this.$store.dispatch("campus", this.$route.params.campus);
  }
};
</script>
