<template>
  <transition name="rightcard">
    <div v-if="$store.getters.lateralMenu" class="load mediumm white pl-3 pr-5">
      <div class="appbar capital primary medium subtitle-2">{{$store.getters.appName}}</div>
      <button-icon-text
        @click.native="navegationTo('Dashboard')"
        class="mb-1 subtitle medium"
        icon="mdi-home"
        :text="$store.getters.homeMenu"
      />

      <!-- ANONYMOUS -->
      <button-icon-text
        v-if="!$store.getters.isAnonymous"
        @click.native="goToProfile"
        class="mb-1 subtitle medium"
        icon="mdi-account"
        :text="$store.getters.profileMenu"
      />
      <button-icon-text
        @click.native="$store.commit('register')"
        v-else
        class="mb-1 subtitle grey-text medium"
        icon="mdi-lock"
        :text="$store.getters.profileMenu"
      />
      <button-icon-text
        v-if="!$store.getters.isAnonymous"
        @click.native="navegationTo('Messages')"
        class="mb-1 subtitle medium"
        icon="mdi-email-variant"
        :text="$store.getters.messageMenu"
      />
      <button-icon-text
        @click.native="$store.commit('register')"
        v-else
        class="mb-1 subtitle grey-text medium"
        icon="mdi-lock"
        :text="$store.getters.messageMenu"
      />
      <!-- END ANONYMOUS -->

      <button-icon-text
        @click.native="navegationTo('Shop')"
        class="mb-1 subtitle medium"
        icon="mdi-cart"
        :text="$store.getters.shopMenu"
      />
      <!-- CONTACT  -->

      <button-icon-text
        @click.native="goToWeb(`https://www.instagram.com/${$store.getters.dashboard.App.instagram}`)"
        class="mb-1 mt-5 subtitle medium"
        icon="mdi-instagram"
        :text="$store.getters.instagram"
      />
      <button-icon-text
        @click.native="goToWeb(`tel:${$store.getters.prefix}${$store.getters.dashboard.App.contact}`)"
        class="mb-1 subtitle medium"
        icon="mdi-phone"
        :text="$store.getters.phoneMenu"
      />

      <!-- ADMIN -->
      <button-icon-text
        v-if="$store.getters.isAdmin"
        @click.native="navegationTo('Send')"
        class="mb-1 mt-5 subtitle medium"
        icon="mdi-email-plus-outline"
        :text="$store.getters.sendMessageMenu"
      />
      <button-icon-text
        v-if="$store.getters.isAdmin"
        @click.native="navegationTo('admin')"
        class="mb-1 subtitle medium"
        icon="mdi-key-variant"
        :text="$store.getters.adminMenu"
      />
      <!-- END ADMIN -->

      <button-icon-text
        @click.native="goToWeb(`${$store.getters.terms}`)"
        class="mb-1 mt-5 subtitle medium"
        icon="mdi-bank"
        :text="$store.getters.termsMenu"
      />
      <button-icon-text
        @click.native="goToWeb(`${$store.getters.developerURL}`)"
        class="mb-1 subtitle medium"
        icon="mdi-lifebuoy"
        :text="$store.getters.developer"
      />
      <button-icon-text
        @click.native="exit"
        class="mb-1 subtitle medium"
        icon="mdi-power"
        :text="$store.getters.exitMenu"
      />
    </div>
  </transition>
</template>
<script>
import ButtonIconText from "../Buttons/ButtonIconText";

export default {
  components: {
    ButtonIconText
  },
  methods: {
    navegationTo(view) {
      this.$store.commit("closed");
      this.$router.replace(`/Home/${view}`).catch(err => {});
    },
    goToProfile() {
      this.$store.commit("closed");
      this.$router
        .push({
          name: "Profile",
          params: { uid: this.$store.getters.myProfile.uid }
        })
        .catch(err => {});
    },
    goToWeb(url) {
      this.$store.commit("closed");
      this.$store.dispatch("goLink", url);
    },
    exit() {
      this.$store.dispatch("logout");
    }
  }
};
</script>
<style scoped>
.appbar {
  height: 50px;
  display: flex;
  align-items: center;
}
.load {
  /* height: 100vh; */
  z-index: 600;
  position: fixed;
}
.rightcard-enter-active {
  will-change: transform;
  animation: right 0.4s;
}
.rightcard-leave-active {
  will-change: transform;
  animation: right 0.4s reverse;
}
@keyframes right {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>