  
<template>
  <transition name="upTicket">
    <div v-if="$store.getters.ticket" class="red load">
      <div class="card bizel light">
        <!-- HEADER -->
        <section class="mb-3">
          <div class="toolbar mt-5 pt-2 center-flex">
            <img class="box" src="../../assets/img/barcode.svg" />
          </div>

          <div class="caption grey-text center mt-1 pr-8 pl-8">
            {{$store.getters.textTerms}}
            {{$store.getters.developer}}
          </div>

          <div class="line-cut mt-3 mb-3 toolbar">
            <div class="circle left-circle"></div>
            <div class="dashed spacer"></div>
            <div class="circle right-circle"></div>
          </div>
          <div class="medium center capital">{{$store.getters.appName}}</div>
        </section>

        <!-- INFORMATION -->
        <section class="mb-3 pr-5 right medium body pl-5">
          <div class="toolbar mb-1">
            <div>{{$store.getters.dateTicket}}</div>
            <div class="spacer capital">{{$store.getters.dataPop.date}}</div>
          </div>
          <div class="toolbar mb-1">
            <div>{{$store.getters.serviceTicket}}</div>
            <div class="spacer">{{$store.getters.dataPop.service}}</div>
          </div>
          <div class="toolbar mb-1">
            <div>{{$store.getters.campusTicket}}</div>
            <div class="spacer">{{$store.getters.dataPop.campus}}</div>
          </div>
          <div class="toolbar mb-1">
            <div>{{$store.getters.hourTicket}}</div>
            <div class="spacer">{{$store.getters.dataPop.hour}}</div>
          </div>
          <div class="toolbar mb-1">
            <div>{{$store.getters.serverTicket}}</div>
            <div class="spacer">{{$store.getters.dataPop.serverName}}</div>
          </div>
          <div class="toolbar mt-3">
            <div class="text">{{$store.getters.priceTicket}}</div>
            <div class="spacer text">{{$store.getters.dataPop.price}}</div>
          </div>
        </section>

        <!-- OPTIONS -->
        <section class="mb-4">
          <div
            class="caption justify mt-2 pr-5 mb-2 grey-text pl-5"
          >{{$store.getters.messageTicket}}</div>
          <div class="center-flex item mr-5 ml-5">
            <!-- CLOSED BUTTON -->
            <button-icon
              @click.native="$store.commit('closed')"
              class="red avatar-big white bizel"
              icon="mdi-close"
            ></button-icon>

            <!-- ANONYMOUS -->
            <button-text
              @click.native="$store.commit('register')"
              v-if="$store.getters.isAnonymous"
              class="grey bizel white ml-2 spacer text uppercased medium"
              :text="$store.getters.doneText"
            ></button-text>

            <!-- DIFERENCE TO CANCEL O ADD -->
            <button-text
              @click.native="cancelShift"
              v-else-if="$store.getters.dataPop.hasOwnProperty('state')"
              class="orange bizel white ml-2 spacer text uppercased medium"
              :text="$store.getters.cancelShiftText"
            ></button-text>
            <button-text
              @click.native="add"
              v-else
              class="green bizel white ml-2 spacer text uppercased medium"
              :text="$store.getters.doneText"
            ></button-text>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import ButtonText from "../Buttons/ButtonText";
import ButtonIcon from "../Buttons/ButtonIcon";
import axios from "axios";
export default {
  components: {
    ButtonText,
    ButtonIcon
  },
  methods: {
    cancelShift() {
      this.$store.dispatch("cancelShift", this.$store.getters.dataPop);
    },
    add() {
      var payload = this.$store.getters.dataPop;
      payload.app = this.$store.getters.database;
      payload.uid = this.$store.getters.myProfile.uid;
      payload.name = this.$store.getters.myProfile.name;
      payload.photo = this.$store.getters.myProfile.photo;
      this.$store.dispatch("addShift", payload);
    }
  }
};
</script>
<style scoped>
.load {
  position: fixed;
  z-index: 500;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashed {
  border-top: 4px dashed var(--primary);
  margin-top: 8px;
  opacity: 0.8;
}
.right-circle {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
.left-circle {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
.circle {
  background: var(--primary);
  opacity: 0.8;
  height: 20px;
  width: 10px;
}
.box {
  width: 180px;
  height: 60px;
}
.card {
  border-radius: 20px;
  position: absolute;
  top: 65px;
  width: 250px;
}
</style>