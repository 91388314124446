<template>
  <div class="overflow-loading">
    <transition name="fade-loading" mode="out-in">
      <loading v-if="$store.getters.profile == null"></loading>
      <master :data="$store.getters.profile" v-else></master>
    </transition>
  </div>
</template>
<script>
import master from "./Master";
import loading from "./Loading";
export default {
  components: {
    master,
    loading
  },
  created() {
    this.$store.commit("profile", null);
    this.$store.dispatch("profile", this.$route.params.uid);
  },
  watch: {
    "$route.params.uid"() {
      this.$store.commit("profile", null);
      this.$store.dispatch("profile", this.$route.params.uid);
    }
  }
};
</script>