<template>
  <img v-if="isPhoto" :class="'form gradient-primary avatar-'+size" :src="photo" />
  <div
    v-else
    :class="'form white avatar-'+size + ' ' + color"
    class="medium center-flex text item uppercased"
  >{{photo.charAt(0)}}</div>
</template>
<script>
export default {
  created() {
    const color = [
      "purple",
      "pink",
      "blue-grey",
      "grey",
      "red",
      "deep-purple",
      "indigo",
      "teal",
      "amber",
      "deep-orange",
      "brown",
      "orange",
      "green",
      "blue"
    ];
    this.color = color[Math.floor(Math.random() * color.length)];
    if (this.photo.includes("http")) {
      this.isPhoto = true;
    }
  },
  props: {
    photo: String,
    size: String
  },
  data: () => ({
    isPhoto: false,
    color: "accent"
  }),
  watch: {
    photo(val) {
      if (this.photo.includes("http")) {
        this.isPhoto = true;
      } else {
        this.isPhoto = false;
      }
    }
  }
};
</script>
<style scoped>
.form:active {
  opacity: 0.5;
  transform: scale(0.9);
}
</style>