<template>
  <div class="overflow-loading">
    <transition name="fade-loading" mode="out-in">
      <loading v-if="$store.getters.admin == null"></loading>
      <master :data="$store.getters.admin" v-else></master>
    </transition>
  </div>
</template>
<script>
import master from "./Master";
import loading from "../Schedule/Loading";
export default {
  components: {
    master,
    loading
  },
  created() {
    this.$store.commit("admin", null);
    this.$store.dispatch("admin");
  }
};
</script>