<template>
  <transition name="upcard">
    <div v-if="$store.getters.sellGift" class="light load">
      <section class="mb-2">
        <div class="subtitle pl-5 medium mb-3 pt-3">{{$store.getters.selectedText}}</div>
        <div class="horizon">
          <gift
            @click.native="selected = item"
            v-for="(item, index) in $store.getters.dashboard.Gifts"
            :key="index"
            class="ml-5 item"
            :data="item"
          ></gift>
          <div class="item pr-5"></div>
        </div>
      </section>
      <section class="pb-4">
        <div class="caption justify pr-5 mb-2 grey-text pl-5">{{$store.getters.messageGift}}</div>
        <div class="center-flex item mr-5 ml-5">
          <button-icon
            @click.native="$store.commit('closed')"
            class="red avatar-big white bizel"
            icon="mdi-close"
          ></button-icon>
          <button-text
            v-if="selected == null"
            class="bizel white grey ml-2 spacer text uppercased medium"
            :text="$store.getters.selectedGift"
          ></button-text>
          <button-text
            @click.native="sell()"
            v-else
            :class="`gifts-${selected.id}`"
            class="bizel white ml-2 spacer text uppercased medium"
            :text="`${selected.name} $${selected.price}`"
          ></button-text>
        </div>
      </section>
    </div>
  </transition>
</template>
<script>
import Gift from "./Gift";
import ButtonText from "../Buttons/ButtonText";
import ButtonIcon from "../Buttons/ButtonIcon";
export default {
  components: {
    Gift,
    ButtonText,
    ButtonIcon
  },
  data: () => ({
    selected: null
  }),
  methods:{
    sell(){
      var payload = this.selected
      payload.seller = this.$store.getters.myProfile.name
      payload.uid = this.$route.params.uid
      this.$store.dispatch('sellGift',payload)
    }
  }
};
</script>

<style scoped>
.load {
  bottom: 0;
  z-index: 800;
  position: fixed;
  width: 100%;
}
</style>