<template>
  <div class="pr-8 pl-8 spacer mb-12">
    <div class="big-title-2 medium">{{$store.getters.signupView}}</div>
    <div class="subtitle capital mt-1">{{$store.getters.appName}}</div>
    <input-icon
      class="mt-3"
      v-model="payload.name"
      :placeholder="$store.getters.inputName"
      type="name"
      icon="mdi-account"
    ></input-icon>
    <input-icon
      class="mt-2"
      v-model="payload.phone"
      :placeholder="$store.getters.inputTel"
      type="tel"
      icon="mdi-phone"
      :max='10'
    ></input-icon>
    <input-icon
      class="mt-2"
      :placeholder="$store.getters.inputEmail"
      type="email"
      icon="mdi-email-mark-as-unread"
      v-model="payload.email"
    ></input-icon>
    <input-icon
      class="mt-2"
      :placeholder="$store.getters.inputPassword"
      type="password"
      icon="mdi-textbox-password"
      v-model="payload.password"
    ></input-icon>
    <button-text
      @click.native="Signup"
      class="white medium accent bizel mt-2 center uppercased"
      :text="$store.getters.textRegister"
    ></button-text>
    <button-text @click.native="$store.dispatch('goLink', $store.getters.terms)" class="body-2 center mt-2" :text="$store.getters.termsRegister"></button-text>
  </div>
</template>
<script>
import ButtonText from "../../components/Buttons/ButtonText";
import InputIcon from "../../components/Inputs/InputIcon";
import axios from "axios";
import firebase from "../../plugins/firebase";
export default {
  components: {
    ButtonText,
    InputIcon
  },
  data: () => ({
    payload: {
      password: "",
      name: "",
      email: "",
      phone: ""
    }
  }),
  methods: {
    Signup() {
      if (!isNaN(this.payload.phone) && this.payload.phone.length == 10) {
        this.$store.dispatch("signup", this.payload);
      } else {
        this.$store.commit("message", {
          text: this.$store.getters.phoneError,
          color: "red"
        });
      }
    }
  }
};
</script>
