<template>
  <transition name="search">
    <div v-if="$store.getters.search" class="load">
      <div class="card light grey-text center-flex">
        <button-icon icon="mdi-account-search" class="ml-4 mr-2"></button-icon>
        <input
          v-model="email"
          class="search normal spacer"
          :placeholder="$store.getters.inputEmail"
          type="email"
          @keydown.enter="search" 
        />
        <button-icon @click.native="search" icon="mdi-magnify" class="mr-5"></button-icon>
      </div>
    </div>
  </transition>
</template>
<script>
import ButtonIcon from "../Buttons/ButtonIcon";
export default {
  components: {
    ButtonIcon
  },
  data: () => ({
    email: null
  }),
  methods: {
    search() {
      this.$store.dispatch("searchUser", this.email);
      this.email = null;
    }
  }
};
</script>
<style scoped>
input:hover,
input:focus,
input:active {
  outline: none;
  background: #fff;
}
.search {
  background: #ffffff;
  border: none;
  color: #313131;
  transition: background 0.6s ease;
}
.load {
  width: 100%;
  z-index: 500;
  position: fixed;
  display: flex;
  align-items: flex-start;
  top: 0;
}
.card {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 600;
}
</style>