<template>
  <div class="width gradient-primary white bizel">
    <div class="pt-3 center-flex">
      <avatar size="normal" class="border-white" :photo="data.serverPhoto"></avatar>
    </div>
    <div class="line-cut toolbar">
      <div class="circle left"></div>
      <div class="dashed mr-1 ml-1 spacer"></div>
      <div class="circle right"></div>
    </div>
    <div class="center pt-1 pr-1 pl-1">
      <div class="text">{{data.hour}}</div>
      <div class="caption capital pa-1">{{data.date}}</div>
    </div>
    <div class="center-flex pt-1 pb-2">
      <button-icon class="red white" icon="mdi-close"></button-icon>
    </div>
  </div>
</template>
<script>
import Avatar from "../../components/Buttons/Avatar.vue";
import ButtonIcon from "../../components/Buttons/ButtonIcon.vue";
export default {
  components: {
    Avatar,
    ButtonIcon
  },
  props: {
    data: Object
  }
};
</script>
<style scoped>
.dashed {
  border-top: 2px dashed #fff;
  margin-top: 8px;
}
.right {
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
}
.left {
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}
.circle {
  background: #fff;
  height: 20px;
  width: 10px;
}
.line-cut {
  width: 100%;
}
.width {
  width: 100px;
}
.width:active {
  opacity: 0.8;
  transform: scale(0.98);
}
</style>