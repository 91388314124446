<template>
  <div class="relative center-flex">
    <span :class="icon" class="mdi icon white"></span>
    <input
      :value="value"
      class="form bizel normal"
      @input="update($event.target.value)"
      :placeholder="placeholder"
      :type="type"
      :maxlength="max"
    />
  </div>
</template>
<script>
export default {
  props: {
    icon: String,
    placeholder: String,
    type: String,
    value: String,
    max:Number
  },

  methods: {
    update: function(value) {
      this.$emit("input", value);
    }
  }
};
</script>
<style scoped>
.icon {
  position: absolute;
  left: 5vw;
  color: rgb(184, 184, 184);
  font-size: 1.1rem;
}
::placeholder {
  color: rgb(184, 184, 184);
}
.form {
  height: 45px;
}
input {
  background: #3535359c;
  border: none;
  color: #313131;
  transition: 0.6s ease;
  width: 100% ;
  padding: 0;
  padding-left: 13vw;
}
input:hover,
input:focus,
input:active {
  outline: none;
  background: #fff;
}
</style>