<template>
  <div class="toolbar bizel">
    <img @click="$router.push({name: 'Schedule', params: { campus: data.name }})" class="avatar-big border-primary item ml-3 gradient-primary" :src="data.photo" />
    <div class="ml-4 mr-3">
      <div @click="$router.push({name: 'Schedule', params: { campus: data.name }})" class="medium pb-1 subtitle">{{data.name}}</div>
      <div @click="$router.push({name: 'Schedule', params: { campus: data.name }})" class="pb-2 body">{{data.information}}</div>

      <div class="toolbar">
        <div class="spacer"></div>
        <button-icon
          @click.native="$store.dispatch('goLink',`https://www.google.com/maps/dir/?api=1&destination=${data.map}`)"
          class="mr-3"
          icon="mdi-map-marker-outline"
        ></button-icon>
        <button-icon
          @click.native="$store.dispatch('goLink',`tel:${$store.getters.prefix}${data.contact}`)"
          class="mr-3"
          icon="mdi-phone-in-talk"
        ></button-icon>
        <button-icon
          @click.native="$store.dispatch('goLink',`https://wa.me/${$store.getters.prefix}${data.contact}`)"
          class
          icon="mdi-whatsapp"
        ></button-icon>
      </div>
      <hr class="ma-1 mr-3" />
    </div>
  </div>
</template>
<script>
import ButtonIcon from "../Buttons/ButtonIcon";

export default {
  components: {
    ButtonIcon
  },
  props: {
    data: Object
  }
};
</script>
