<template>
  <div>
    <div class="big-title pl-5 white medium mb-5">{{$store.getters.messageMenu}}</div>

    <section class="mb-5">
      <div class="subtitle pl-5 white spacer medium mb-3">{{$store.getters.newsText}}</div>
      <div v-for="(item, index) in data" :key="index" class="pr-5 pl-5 mb-3 messages toolbar">
        <avatar class="white" size="normal" :photo="item.photo"></avatar>
        <div class="spacer medium ml-3 mr-1">
          <div class="text mb-1 capital">{{item.name}}</div>
          <div class="body">{{item.text}}</div>
          <div class="toolbar mt-2">
            <div class="spacer"></div>
            <div class="body">{{item.date}}</div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Avatar from "../../../components/Buttons/Avatar";
export default {
  created() {
    this.$store.commit("news", null);
  },
  components: {
    Avatar
  },
  props: {
    data: Array
  }
};
</script>
<style scoped>
.messages:nth-child(-n + 2) {
  color: white;
}
</style>