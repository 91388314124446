<template>
  <div :class="`gradient-shifts-${index}`" class="toolbar bizel pl-5 pr-5 height center-flex">
    <span :class="icon" class="mdi opactiy mr-5"></span>
    <div class="spacer mr-3">
      <div class="normal">{{data}}</div>
      <div class="body">{{interval}} {{$store.getters.timeInterval}}</div>
    </div>
    <button-icon icon="mdi-information-variant"></button-icon>
  </div>
</template>
<script>
import ButtonIcon from "../../components/Buttons/ButtonIcon.vue";
export default {
  components: {
    ButtonIcon
  },
  props: {
    data: String,
    index: Number,
    interval: Number
  },
  computed: {
    icon() {
      switch (this.index) {
        case 0:
          return "mdi-weather-partly-cloudy";
        case 1:
          return "mdi-white-balance-sunny";
        case 2:
          return "mdi-weather-night";
      }
    }
  }
};
</script>
<style scoped>
.height {
  height: 45px;
}
.toolbar:active {
  opacity: 0.8;
  transform: scale(0.98);
}
</style>