<template>
  <div class="view black center-flex">
    <div class="appbar white anchor">
      <transition name="fade" mode="out-in">
        <button-icon
          v-if="$route.name !== 'Launcher'"
          @click.native="$router.push('/Auth/Launcher')"
          class="ml-5"
          icon="mdi-arrow-left"
        ></button-icon>
      </transition>
    </div>
    <div class="spacer"></div>
    <div class="anchor center-flex white zindex-100 mb-5">
      <transition name="fade-home" mode="out-in">
        <router-view />
      </transition>
    </div>
    <div class="spacer"></div>
    <div class="body zindex-300 pb-2 white">{{$store.getters.version}}</div>
    <div class="wave one"></div>
    <div class="wave two"></div>
    <div class="wave three"></div>
  </div>
</template>
<script>
import "../../assets/css/weves.css";
import ButtonText from "../../components/Buttons/ButtonText.vue";
import ButtonIcon from "../../components/Buttons/ButtonIcon.vue";
export default {
  components: {
    ButtonText,
    ButtonIcon
  }
};
</script>
<style scoped>
.appbar {
  height: 50px;
  display: flex;
  align-items: center;
}
.view {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  flex-direction: column;

  background-image: linear-gradient(rgba(0, 0, 0, 0.3)),
    url("../../assets/img/background.jpg");
  background-size: cover;
  background-position: center right;
}
</style>