<template>
  <div class="pr-8 pl-8 spacer">
    <div class="big-title-2 medium">{{$store.getters.loginView}}</div>
    <div class="subtitle capital mt-1">{{$store.getters.appName}}</div>
    <input-icon
      v-model="payload.email"
      class="mt-3"
      :placeholder="$store.getters.inputEmail"
      type="email"
      icon="mdi-email-mark-as-unread"
    ></input-icon>
    <input-icon
      v-model="payload.password"
      class="mt-2"
      :placeholder="$store.getters.inputPassword"
      type="password"
      icon="mdi-textbox-password"
    ></input-icon>
    <button-text
      @click.native="Login"
      class="medium accent bizel mt-2 center uppercased"
      :text="$store.getters.textLogin"
    ></button-text>
    <div class="toolbar">
      <div class="spacer"></div>
      <button-text @click.native="$store.dispatch('goLink', $store.getters.reset)" class="text mt-2 center" :text="$store.getters.resetPassword"></button-text>
      <div class="spacer"></div>
    </div>
  </div>
</template>
<script>
import ButtonText from "../../components/Buttons/ButtonText";
import InputIcon from "../../components/Inputs/InputIcon";
export default {
  components: {
    ButtonText,
    InputIcon
  },
  data: () => ({
    payload: {
      email: null,
      password: null
    }
  }),
  methods: {
    Login() {
      this.$store.dispatch("login", this.payload);
    }
  }
};
</script>

