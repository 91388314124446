<template>
  <transition name="turn" mode="out-in">
    <div
      @click.stop="toBack"
      key="1"
      v-if="front"
      :class="`gifts-${color}`"
      class="bizel height pa-5"
    >
      <div class="toolbar relative">
        <div class="avatar-normal zindex-100 light"></div>
        <div class="avatar-normal circle light"></div>
        <div class="one light ml-5"></div>
        <div class="two light"></div>

        <div class="spacer"></div>
        <div
          v-if="color !=  0"
          class="subtitle mr-3 center-flex white"
        >{{('0' + data.tickets).slice(-2)}} / {{('0' + data.used).slice(-2)}}</div>
        <div v-else class="subtitle mr-3 center-flex white">{{$store.getters.offTickets}}</div>
      </div>
      <div class="spacer"></div>
      <div class="normal big-title medium white">{{data.name}}</div>
      <div  class="text white">{{$store.getters.serverTicket}} {{data.seller}}</div>
    </div>
    <!-- back front -->
    <div
      @click.stop="toFront"
      v-else
      key="2"
      :class="`gifts-${color}`"
      class="bizel relative height pa-5"
    >
      <div class="bar anchor zindex-200 grey-text"></div>
      <div class="body medium developer zindex-200 white">{{$store.getters.available}} {{data.used}} {{$store.getters.ticketsText}}</div>
      <div class="one light ml-5"></div>
      <div class="two light ml-5"></div>
      <div class="text medium white">{{$store.getters.termsMenu}}</div>
      <div class="body white mt-1">{{data.information}}</div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data: () => ({
    front: true
  }),
  methods: {
    toBack() {
      this.front = false;
    },
    toFront() {
      this.front = true;
    }
  },
  computed: {
    color() {
      if (this.data.used >= this.data.tickets) {
        return 0;
      } else {
        return this.data.id;
      }
    }
  }
};
</script>
<style scoped>
.developer {
  right: 10px;
  bottom: 8px;
  position: absolute;
}
.bar {
  background: #303030;
  height: 10vw;
  left: 0;
  bottom: 25px;
  position: absolute;
}
.circle {
  position: absolute;
  opacity: 0.7;
  margin-left: 6vw;
}
.one {
  top: -13vw;
  right: -10vw;
  height: 48vw;
  width: 48vw;
  border-radius: 50%;
  position: absolute;

  opacity: 0.1;
}
.two {
  top: 10vw;
  right: -10vw;
  height: 65vw;
  opacity: 0.1;
  width: 65vw;
  border-radius: 50%;
  position: absolute;
}

.height {
  height: 40vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/* .height:active {
  opacity: 0.8;
  transform: scale(0.98);
} */
</style>