<template>
  <div class="view light">
    <div class="toolbar ml-5 pt-3">
      <avatar size="normal" :photo="$store.getters.myProfile.photo"></avatar>
      <div class="text pa-2 ml-2 capital border-primary bizel">{{$store.getters.myProfile.name}}</div>
    </div>
    <hr class="ml-2 mr-2" />
    <div class="ml-5 text medium mb-1 mt-1">{{$store.getters.writeMessage}}:</div>
    <textarea v-model="text" class="area spacer text pr-5 pl-5"></textarea>
    <div class="toolbar ma-5">
      <button-icon
        @click.native="$router.replace('/Home/Dashboard')"
        class="red avatar-big white bizel"
        icon="mdi-close"
      ></button-icon>
      <button-text
        class="green spacer ml-2 bizel white medium"
        @click.native="push"
        :text="$store.getters.sendMessageButton"
      ></button-text>
    </div>
  </div>
</template>
<script>
import Avatar from "../../components/Buttons/Avatar";
import ButtonText from "../../components/Buttons/ButtonText";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
export default {
  components: {
    Avatar,
    ButtonText,
    ButtonIcon
  },
  data: () => ({
    text: null
  }),
  methods: {
    push() {
      if (this.text) {
        this.$store.dispatch("sendMessage", this.text);
      } else {
        this.$store.commit("message", {
          text: this.$store.getters.EmpetyError,
          color: "red"
        });
      }
    }
  }
};
</script>
<style scoped>
.view {
  height: calc(100vh - 50px);
  width: 100vw;
  display: flex;
  flex-direction: column;
}
textarea {
  outline: none;
  border-style: none;
  border-color: none;
  width: 100vw;
  box-sizing: border-box;
}
</style>