<template>
  <div class="pr-8 pl-8 spacer">
    <div class="center-flex mb-3">
      <img class="logotype" src="../../assets/img/logotype.png" />
    </div>
    <div class="big-title-2 capital medium">{{$store.getters.appName}}</div>
    <div class="subtitle mt-1">{{$store.getters.developer}}</div>
    <button-text
      @click.native="$router.push('/Auth/Signup')"
      class="mt-3 medium white normal accent bizel center uppercased"
      :text="$store.getters.textRegister"
    ></button-text>
    <button-text
      @click.native="$router.push('/Auth/Login')"
      class="medium border-white bizel mt-2 center uppercased"
      :text="$store.getters.textLogin"
    ></button-text>
    <div class="toolbar mb-12 pb-8">
      <div class="spacer"></div>
      <button-text
        @click.native="$store.dispatch('anonymous')"
        class="normal mt-2 center"
        :text="$store.getters.textGuest"
      ></button-text>
      <div class="spacer"></div>
    </div>
  </div>
</template>
<script>
import ButtonText from "../../components/Buttons/ButtonText.vue";
export default {
  components: {
    ButtonText
  }
};
</script>
<style scoped>
.logotype {
  width: 40%;
}
</style>