<template>
  <div>
    <div class="big-title pl-5 white medium mb-5">{{$store.getters.adminMenu}}</div>
    <!-- Servers -->
    <section class="mb-5">
      <div class="toolbar">
        <div class="subtitle pl-5 white spacer medium mb-3">{{$store.getters.serversText}}</div>
        <div class="capital normal medium white mr-5">{{data.servidors[selected.server].name}}</div>
      </div>
      <div class="horizon">
        <avatar
          @click.native="select(index,'server')"
          v-for="(item, index) in data.servidors"
          :key="index"
          :class="index !== selected.server ? 'no-selected' : 'selected'"
          class="ml-5 item"
          size="max"
          :photo="item.photo"
        ></avatar>
        <div class="item pr-5"></div>
      </div>
    </section>
    <!-- Days -->
    <section class="mb-5">
      <div class="toolbar">
        <div class="subtitle pl-5 spacer medium mb-3 capital">{{data.week[selected.day].month}}</div>
        <div class="capital normal medium mr-5">{{data.week[selected.day].dayWeek}}</div>
      </div>
      <div class="horizon">
        <days
          @click.native="select(index,'day')"
          v-for="(item, index) in data.week"
          :key="index"
          :selected="index !== selected.day ? '' : 'gradient-primary white'"
          class="ml-5 item"
          size="big"
          :data="item"
        ></days>
        <div class="item pr-5"></div>
      </div>
    </section>

    <!-- Schedule -->
    <section class="mb-5">
      <div v-if="empty" class="pa-12 mt-8 grey-text flex-colum center-flex">
        <div class="mdi subtitle mdi-alert-circle-outline"></div>
        <div
          class="body-2 center pr-8 pl-8 mt-2"
        >{{data.servidors[selected.server].name}} {{$store.getters.offServiceAdminText}}</div>
      </div>

      <div
        v-else
        v-for="(item, index) in data.servidors[selected.server].schedule[selected.day]"
        :key="index"
        class="pr-5 mb-5 pl-5"
      >
        <div
          v-if="item.occupied.length !== 0"
          class="mr-3 normal medium mb-1"
        >{{$store.getters[item.name]}}</div>
        <shifts
          @click.native="select(shift,'shift')"
          :index="index"
          v-for="(shift, index2) in item.occupied"
          :data="shift"
          :key="index2"
          class="spacer namespace mb-2"
        ></shifts>
      </div>
    </section>
  </div>
</template>
<script>
import Avatar from "../../../components/Buttons/Avatar";
import Days from "../../../components/Schedule/Days";
import Shifts from "../../../components/Admin/Shifts";
export default {
  components: {
    Avatar,
    Days,
    Shifts
  },
  props: {
    data: Object
  },
  data: () => ({
    selected: {
      server: 0,
      day: 2,
      service: 0
    }
  }),
  methods: {
    select(index, param) {
      switch (param) {
        case "service":
          this.selected.service = index;
          break;
        case "day":
          this.selected.day = index;
          break;
        case "server":
          this.selected.server = index;
          break;
        case "shift":
          this.$router.push({ name: "Profile", params: { uid: index[1].uid } });
          break;
      }
    }
  },
  computed: {
    empty() {
      const block = this.data.servidors[this.selected.server].schedule[
        this.selected.day
      ];
      return (
        block[0].occupied.length == 0 &&
        block[1].occupied.length == 0 &&
        block[2].occupied.length == 0
      );
    }
  }
};
</script>
<style scoped>
.namespace {
  margin-left: 90px;
}
.no-selected {
  opacity: 0.3;
  border: 2px solid white;
  box-sizing: border-box;
}
.selected {
  border: 2px solid var(--primary);
  box-sizing: border-box;
}
</style>