  
<template>
  <transition name="upTicket">
    <div v-if="$store.getters.newMessage" class="red load">
      <div class="card bizel light">
        <!-- HEADER -->
        <section class="mb-1">
          <div class="toolbar mt-5 pt-2 center-flex">
            <img class="box" src="../../assets/img/message.svg" />
          </div>
          <div class="medium subtitle center capital">{{$store.getters.newMessageText}}</div>
        </section>
        <!-- Message -->
        <section class="mb-4 grey-text text center pr-5 pl-5">{{$store.getters.newMessageBody}}</section>
        <!-- OPTIONS -->
        <section class="mb-4">
          <div class="center-flex item mr-5 ml-5">
            <button-text
              @click.native="goTo"
              class="accent bizel white spacer text uppercased medium"
              :text="$store.getters.goToMessages"
            ></button-text>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import ButtonText from "../Buttons/ButtonText";
import ButtonIcon from "../Buttons/ButtonIcon";
export default {
  components: {
    ButtonText,
    ButtonIcon
  },
  methods: {
    goTo() {
      this.$store.commit("closed");
      this.$router.push("/Home/Messages").catch(err => {});
    }
  }
};
</script>

<style scoped>
.load {
  position: fixed;
  z-index: 800;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 150px;
  height: 120px;
}
.card {
  border-radius: 20px;
  position: absolute;
  top: 25vh;
  width: 250px;
}
</style>