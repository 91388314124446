var firebase = require("firebase/app");
require("firebase/auth");
require("firebase/performance");
var config = {
  apiKey: "AIzaSyAEEql97GUEBsXP-tEQP4H1c2TpvF1ELBw",
  authDomain: "api-7211816181825408403-893427.firebaseapp.com",
  databaseURL: "https://api-7211816181825408403-893427.firebaseio.com",
  projectId: "api-7211816181825408403-893427",
  storageBucket: "api-7211816181825408403-893427.appspot.com",
  messagingSenderId: "579073892766",
  appId: "1:579073892766:web:89297e7f775516dc6db68f"
};
export default firebase.initializeApp(config);
