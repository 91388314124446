<template>
  <div class="overflow-loading">
    <transition name="fade-loading" mode="out-in">
      <loading class="fixed-loading" v-if="$store.getters.dashboard == null"></loading>
      <master :data="$store.getters.dashboard" v-else></master>
    </transition>
  </div>
</template>
<script>
import master from "./Master";
import loading from "./Loading";
export default {
  components: {
    master,
    loading
  }
};
</script>
