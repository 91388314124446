<template>
  <div class="form pr-3 pl-3 center center-flex">{{text}}</div>
</template>
<script>
export default {
  props: {
    text: String
  }
};
</script>
<style scoped>
.form {
  height: 45px;
}
.form:active{
  opacity: 0.5;
  transform: scale(0.98);
}
</style>