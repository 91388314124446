<template>
  <div class="avatar-normal center-flex">
    <span :class="icon" class="mdi"></span>
  </div>
</template>
<script>
export default {
  props: {
    icon: String
  }
};
</script>
<style scoped>
.avatar-normal:active {
  opacity: 0.5;
  transform: scale(0.98);
}
</style>