<template>
  <div class="card item mb-1 bizel relative gradient-primary bizel anchor">
    <span class="mdi white mdi-web info"></span>

    <div class="white flex-colum center-flex">
      <div class="mdi subtitle mdi-cart"></div>
      <div class="body center pr-8 mt-2 pl-8">{{$store.getters.moreShopText}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  }
};
</script>
<style scoped>
.info {
  position: absolute;
  right: 3vw;
  top: 2vw;
}
.card {
  height: 60vw;
  width: 40vw;
}
.card:active {
  opacity: 0.8;
  transform: scale(0.98);
}
</style>