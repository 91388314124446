const getters = {
  developer: () => "Complex Software",
  developerURL: () => "https://www.complex.com.co/",
  terms: () => "https://www.complex.com.co/terminos/",
  reset: () => "http://reservapps.complex.com.co/",

  textRegister: () => "Regístrate Gratis",
  textLogin: () => "Iniciar Sesión",
  textGuest: () => "Acceso de Invitado",
  textTerms: () =>
    `Copyright all rights reserved © ${new Date().getFullYear()} Intellectual Property`,
  resetPassword: () => "¿Olvidaste tu contraseña?",
  inputEmail: () => "Correo Electrónico",
  inputName: () => "Nombres",
  inputShop: () => "Producto",
  inputTel: () => "Teléfono ",
  inputPassword: () => "Contraseña",
  loginView: () => "Ingresar",
  signupView: () => "Registro",
  termsRegister: () => "He leído y acepto los términos y condiciones de Uso",
  textNext: () => "Continuar",
  textBack: () => "Atrás",
  textSkip: () => "Saltar",
  serversText: () => "Servidores",
  servicesText: () => "Servicios",
  shopText: () => "Productos",
  campusText: () => "Sedes",
  giftsText: () => "Tarjetas Prepago",
  galeryText: () => "Galería fotográfica",
  moreText: () => "Ver más",
  available: () => "Han usado",
  moreShopText: () => "Ver más productos",
  offServiceText: () =>
    "No hay servicios disponibles, intenta buscar otra fecha",
  offServiceAdminText: () => "Aún no tiene citas programadas para este dia",

  // INTERVALS
  Mañana: () => "Mañana",
  Tarde: () => "Tarde",
  Noche: () => "Noche",

  profileMenu: () => "Perfil",
  homeMenu: () => "Inicio",
  messageMenu: () => "Mensajes",
  facebook: () => "Facebook",
  instagram: () => "Instagram",
  twitter: () => "Twitter",
  phoneMenu: () => "Servicio al Cliente",
  shopMenu: () => "Tienda Oficial",
  webMenu: () => "Sitio web",

  sendMessageButton: () => "Enviar Mensaje",
  writeMessage: () => "Mensaje",
  sendMessageMenu: () => "Escribir mensaje",
  adminMenu: () => "Administrador",
  termsMenu: () => "Términos & Condiciones",
  exitMenu: () => "Salir",
  newsText: () => "Más Recientes",
  canceledText: () => "Cancelados",
  confirmedText: () => "Confirmados",
  schedulesText: () => "Próximos Servicios",
  cancelText: () => "Cancelar",
  timeInterval: () => "minutos",
  dateBuy: () => "Fecha de compra",
  ticketsText: () => "Tickets",
  doneText: () => "Confirmar",
  messageTicket: () =>
    "Puedes cancelar en cualquier momento, recuerda estar al menos 10 minutos antes para tu cita.",

  dateTicket: () => "Fecha",
  serviceTicket: () => "Servicio",
  campusTicket: () => "Lugar",
  hourTicket: () => "Hora",
  serverTicket: () => "Servidor",
  priceTicket: () => "Total",
  selectedText: () => "Seleccionar",
  selectedGift: () => "Ninguno",
  messageGift: () =>
    "Recuerda que esta acción no podrá ser revertida, confirma que estás seguro de agregar este plan.",
  addGift: () => "Agregar Plan",
  cancelShiftText: () => "Cancelar Reserva",
  chargeTicket: () => "Cobrar Ticket",
  newMessageText: () => "Nuevos Mensajes",
  registerText: () => "Upps!",
  registerOption1: () => "registrarme",
  registerOption2: () => "Después",
  registerBody: () => "Debes tener una cuenta para acceder a esta opción.",
  goToMessages: () => "Ir a Mensajes",
  newMessageBody: () => "Tienes nuevos mensajes en tu bandeja de entrada",
  confirmTitle: () => "¿Estás seguro?",
  offTickets: () => "Sin Tickets",
  confirmBody: () => "Recuerda que esta acción no podrá ser revertida.",
  offMyServiceText: () => "No tienes ninguna reservación pendiente.",
  itemInformationWP: () => "¡Hola!, Quisiera recibir más información sobre",
  sendDone: () => "Se envió el mensaje a todos de forma correcta",
  sellDone: () => "Se agrego de forma correcta la tarjeta prepaga.",
  alertText: () => "Recuerda que tienes una reserva",
  cancelDone: () =>
    " Se cancelo su reservación de forma exitosa, recuerda que puedes reprogramarte en cualquier momento.",
  chargeDone: () => " Se ha cobrado un ticket de forma correcta.",
  // ERROR'S
  phoneError: () =>
    "[100] Upps! Tenemos problemas para validar su información, verifique su número de contacto e inténtelo nuevamente.",
  SyncError: () =>
    "[200] Upps! este espacio ya no está disponible, intenta con otra hora u otro día.",
  EmpetyError: () =>
    "[300] Upps! Debes escribir algún mensaje para poder continuar.",
  SendError: () =>
    "[400] Upps! se produjo un error al enviar el mensaje, inténtalo nuevamente.",
  offUserError: () =>
    "[500] Upps! No se puedo encontrar el usuario, inténtalo nuevamente."
};

export default {
  getters
};
