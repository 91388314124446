<template>
  <div class="toolbar mr-5 mb-2 ml-5">
    <img :src="data[0]" class="photo-big gradient-primary" />
    <div>
      <img :src="data[1]" class="photo gradient-primary ml-2 mb-2" />
      <img :src="data[2]" class="photo gradient-primary ml-2" />
    </div>
    <div>
      <img :src="data[3]" class="photo gradient-primary ml-2 mb-2" />
      <div class="photo ml-2 gradient-primary white flex-colum center-flex">
        <div class="mdi subtitle mdi-instagram"></div>
        <div class="body mt-1">{{$store.getters.moreText}}</div>
      </div>
    </div>
  </div>
</template><script>
export default {
  props: {
    data: Array
  }
};
</script><style scoped>
.photo {
  height: 21vw;
  width: 21vw;
}
.photo-big {
  height: 44vw;
  width: 44vw;
}
</style>