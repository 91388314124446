<template>
  <div class="cell">
    <span :class="icon" class="mdi avatar-normal center-flex"></span>
    <span class="spacer ml-1 left">{{text}}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    icon: String
  }
};
</script>
<style scoped>
.cell {
  display: flex;
  align-items: center;
}
.toolbar:active {
  opacity: 0.5;
  transform: scale(0.98);
}
</style>