<template>
  <div>
    <transition name="background-popup">
      <div @click.stop="$store.commit('closed')" v-if="$store.getters.popup" class="primary view">
        <div class="icon center-flex">
          <button-icon class="medium primary white" icon="mdi-close" />
        </div>
      </div>
    </transition>
    <shop-detail></shop-detail>
    <message></message>
    <lateral-menu></lateral-menu>
    <search></search>
    <ticket></ticket>
    <loading></loading>
    <new-message></new-message>
    <sell-gift></sell-gift>
    <confirm></confirm>
    <shop-search></shop-search>
    <register></register>
  </div>
</template>
<script>
import ButtonIcon from "../Buttons/ButtonIcon";
import ShopSearch from "../Shop/ShopSearch";
import NewMessage from "./NewMessage";
import Message from "./Message";
import Register from "./Register";
import Confirm from "./Confirm";
import ShopDetail from "./ShopDetail";
import LateralMenu from "./LateralMenu";
import Ticket from "./Ticket";
import Loading from "./Loading";
import Search from "./Search";
import SellGift from "./SellGift";
export default {
  components: {
    Register,
    Message,
    ShopDetail,
    LateralMenu,
    Ticket,
    ButtonIcon,
    Search,
    SellGift,
    Loading,
    Confirm,
    ShopSearch,
    NewMessage
  }
};
</script>

<style scoped>
.icon {
  height: 50px;
  position: absolute;
  right: 5vw;
}
.view {
  height: 100vh;
  width: 100vw;
  z-index: 500;
  opacity: 0.8;
  background: var(--primary);
  position: fixed;
}
</style>