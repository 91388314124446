<template>
  <div key="1" :class="`gifts-${data.id}`" class="bizel height pa-5">
    <div class="toolbar relative">
      <div class="avatar-small zindex-100 light"></div>
      <div class="avatar-small circle light"></div>
      <div class="one light ml-5"></div>
      <div class="two light"></div>

      <div class="spacer"></div>
      <div class="normal white">{{('0' + data.tickets).slice(-2)}} {{$store.getters.ticketsText}}</div>
    </div>
    <div class="spacer"></div>
    <div class="normal subtitle medium white">{{data.name}}</div>
    <div class="text white">{{data.price}}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  }
};
</script>
<style scoped>
.bar {
  background: #303030;
  height: 5vw;
  left: 0;
  bottom: 10px;
  position: absolute;
}
.circle {
  position: absolute;
  opacity: 0.7;
  margin-left: 3vw;
}
.one {
  top: -13vw;
  right: -10vw;
  height: 48vw;
  width: 48vw;
  border-radius: 50%;
  position: absolute;
  opacity: 0.1;
}
.two {
  top: 10vw;
  right: -10vw;
  height: 65vw;
  opacity: 0.1;
  width: 65vw;
  border-radius: 50%;
  position: absolute;
}
.height {
  height: 30vw;
  width: 50vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.height:active {
  opacity: 0.8;
  transform: scale(0.95);
}
</style>