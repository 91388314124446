import Vue from "vue";

import VueRouter from "vue-router";

import firebase from "../plugins/firebase";
import Auth from "../views/Auth/Auth";
import Launcher from "../views/Auth/Launcher";
import Login from "../views/Auth/Login";
import Signup from "../views/Auth/Signup";
import Home from "../views/Home/Home";
import Send from "../views/Home/Send";
import Shop from "../views/Home/Shop";
import Admin from "../views/Home/Admin/Admin";
import Dashboard from "../views/Home/Dashboard/Dashboard";
import Messages from "../views/Home/Messages/Messages";
import Profile from "../views/Home/Profile/Profile";
import Schedule from "../views/Home/Schedule/Schedule";
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/Auth/Launcher"
  },
  {
    path: "/Auth",
    name: "Auth",
    component: Auth,
    children: [
      {
        name: "Launcher",
        component: Launcher,
        path: "Launcher"
      },
      {
        name: "Login",
        component: Login,
        path: "Login"
      },
      {
        name: "Signup",
        component: Signup,
        path: "Signup"
      }
    ]
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: {
      lock: true
    },
    children: [
      {
        name: "Dashboard",
        component: Dashboard,
        path: "Dashboard"
      },
      {
        path: "Schedule/:campus",
        component: Schedule,
        name: "Schedule"
      },
      {
        path: "Messages",
        component: Messages,
        name: "Messages"
      },
      {
        path: "Profile/:uid",
        component: Profile,
        name: "Profile"
      },
      {
        path: "Admin",
        component: Admin,
        name: "Admin"
      },
      {
        path: "Send",
        component: Send,
        name: "Send"
      },
      {
        path: "Shop",
        component: Shop,
        name: "Shop"
      }
    ]
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  let usuario = firebase.auth().currentUser;
  let key = to.matched.some(record => record.meta.lock);
  if (!usuario && key) {
    next("/Auth/Launcher");
  } else if (usuario && !key) {
    next("/Home/Dashboard");
  } else {
    next()
  }
});
export default router;
